import React from "react";
import man1 from "../images/man1.png";
import man2 from "../images/man2.png";
import man3 from "../images/man3.png";
import man4 from "../images/man4.png";
import manwz from "../images/WZ.png";

const Section6 = () => {
  return (
    <>
      <section id="sec6">
        <div className="container">
          <h1 className="sec6h1">Our Team</h1>
          <p className="sec6p">
          Our team of experts brings a wealth of knowledge and experience to the table, providing vital support for our mission. We are proud to have such an outstanding group working together on our cause!
          </p>
          <div className="row">

           

            <div className="col-lg-3" id="tablet">
              <img style={{width:"100%"}} src={manwz} />

              <h4 className="divh1">Waqar Zaka</h4>
              <h4 className="divp">Chief Executive Officer </h4>
            </div>




          </div>
        </div>
      </section>
    </>
  );
};

export default Section6;
