import React  from "react";
import wallet from "../images/wallet.png"
import laptop from "../images/Laptop.png"
import chain from "../images/chain.png"
import hand from "../images/hand.png"
import machine from "../images/machine.png"
import metaverse from "../images/metaverse.png"

const Section3 = ()=>{
    return(
        <>
        <section id="sec3">

        <div className="container">
            <h1 className="sec3h1">Main Services</h1>
            <p className="sec3p">Discover the possibilities of Web3.0 and experience what a new horizon in the internet universe looks like!
</p>
            <div className="row">

                <div className="col-lg-6" id="samsung">

<div className="step1">

    <div>
        <img style={{width:"100%"}} src={wallet}/>
    </div>

    <div className="h4p">
        <h4>1. Wallet Integration</h4>
        <p className="ph4">Looking to make the leap into wallet development with Web3? Get ready for fast, secure and reliable blockchain transactions backed by industry-leading technology. Unlock unprecedented possibilities in crypto operations today!</p>
    </div>

</div>


<div className="step2">

    <div>
        <img style={{width:"100%"}} src={laptop}/>
    </div>

    <div className="h4p">
        <h4>3. Dapps Development</h4>
        <p className="ph4">Creating powerful decentralized applications (Dapps) is the future of software development. From complete control over data, to added trust and transparency between users - developing Dapps has quickly become an essential part of tech innovation in today's world.</p>
    </div>

</div>



<div className="step3">

    <div>
        <img style={{width:"100%"}} src={chain}/>
    </div>

    <div className="h4p">
        <h4>5. Block Chain Consulting</h4>
        <p className="ph4">Our blockchain consulting services help companies move forward with confidence, delivering tailored strategies to meet their unique needs. With expertise in cutting-edge technology and insights into real world implementation, our team ensures businesses leverage the full potential of blockchain solutions for increased efficiency and profitability!</p>
    </div>

</div>

                </div>






                <div className="col-lg-6" id="samsung">

<div className="step1">

    <div>
        <img style={{width:"100%"}} src={hand}/>
    </div>

    <div className="h4p">
        <h4>2. ETH Token Development</h4>
        <p className="ph4">Exploring the possibilities of ETH token development, businesses can gain a competitive edge and capitalize on new opportunities by harnessing this powerful technology.</p>
    </div>

</div>


<div className="step2">

    <div>
        <img style={{width:"100%"}} src={machine}/>
    </div>

    <div className="h4p">
        <h4>4. Solidity Development</h4>
        <p className="ph4">With expertise in Solidity, we bring the power of smart contracts to your business endeavors with reliable and secure development. Let us help you maximize efficiency and unlock new opportunities through blockchain technology. We provide comprehensive support for smart contract deployment on the Ethereum blockchain</p>
    </div>

</div>



<div className="step3">

    <div>
        <img style={{width:"100%"}} src={metaverse}/>
    </div>

    <div className="h4p">
        <h4>6. NFT Development</h4>
        <p className="ph4">Unlock the potential of non-fungible tokens (NFTs) with specialized development services to develop new applications and models. Create unique digital products within a secure, reliable ecosystem utilizing NFT technology’s innovative features. Unlocking a new world of opportunities, NFT development is revolutionizing the token landscape and enabling unprecedented digital ownership.</p>
    </div>

</div>

                </div>

            </div>

        </div>
        

        </section>
        </>
    )
}

export default Section3;