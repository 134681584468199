import React from "react";
import img2 from "../images/img2.png";
import img3 from "../images/img3.png";

const Section2 = () => {
  return (
    <>
      <section id="sec2">
        <div className="container">
          <h1 className="sech2">What Is TenUp FZCO?</h1>
          <div className="row">
            <div className="col-lg-4">
              <p className="sec2p">The fastest growing & community friendly</p>

              <img className="img2" src={img2} />
            </div>

            <div className="col-lg-8">
              <p className="sec2pp">
                Take your business to unprecedented levels with advanced development tools, setting yourself up to make an iconic impact on this ever-evolving arena. Seize this opportunity - be part of tomorrow's internet today!
{" "}
              </p>

              <div className="sec2div2">
                <h1 className="sec2h1h1">
                  TenUp<br></br>
                  FZCO?
                </h1>

                <img className="img3" src={img3} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section2;
